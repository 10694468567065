import React, { useState } from 'react';
import {
  Box,
  Stack,
  IconButton,
  Popover,
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemButton,
  Button,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import PhotoSizeSelectSmallIcon from '@mui/icons-material/PhotoSizeSelectSmall';
import { WIDGET_SIZE_OPTIONS, WIDGET_THEMES } from '../../../constants/widgetConfig.constants';
import { useUnifiedDashboardStore } from '../../../store/UnifiedDashboardStore';
import { useNavigate } from 'react-router-dom';
import CustomDialog from '../../../globalComponents/dialogs/CustomDialog';
import { PERMISSION_TAGS } from '../../../constants/permissions';
import usePermissionCheck from '../../../utilities/customHooks/usePermissionCheck';

interface WidgetStructure {
  metrics: Record<string, any>; // Adjust as per your structure
  plantIds: string[];
  sensorId: string;
  plantName: any;
}

interface TimeFrame {
  startDate: string | null;
  endDate: string | null;
}

interface GeneralisedExpandedWidgetHeaderProps {
  onConfigButtonPress: () => void;
  onWidgetSizeChange: any;
  onWidgetThemeChange: any;
  widgetType: string;
  onDeleteButtonPress: () => void;
  onCloseHeader: () => void;
  widgetStructure: WidgetStructure;
  timeFrame: TimeFrame;
}

export default function GeneralisedWidgetConfigHeader(props: GeneralisedExpandedWidgetHeaderProps) {
  const {
    onConfigButtonPress,
    onWidgetSizeChange,
    widgetType,
    onDeleteButtonPress,
    onWidgetThemeChange,
    onCloseHeader,
    widgetStructure,
    timeFrame,
  } = props;

  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { setAllowDrag } = useUnifiedDashboardStore();
  const [openSizeMenu, setOpenSizeMenu] = useState(false);
  const [openThemeMenu, setOpenThemeMenu] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleConfigButtonPress = (event: any) => {
    setAllowDrag(false);
    event.stopPropagation();
    onConfigButtonPress();
  };

  const handleDataCorrectionPress = () => {
    const metricKeys = Object.keys(widgetStructure?.metrics);

    if (metricKeys.length > 1) {
      setDialogOpen(true);
    } else {
      const metricKey = metricKeys[0];
      const sensorId = widgetStructure?.metrics[metricKey]?.sensorId; // Fetch sensorId
      navigateToDataCorrectionPage(metricKey, sensorId);
    }
  };

  const navigateToDataCorrectionPage = (metricKey: string, sensorId: string) => {
    const sensorTag = renderMetricDisplayName(metricKey);
    const plantId = widgetStructure?.plantIds?.[0];
    const plantName = widgetStructure?.plantName;
    const sensorName = widgetStructure?.metrics[metricKey]?.nickName;

    const startDate = timeFrame?.startDate ? new Date(timeFrame.startDate) : null;

    const updatedWidgetStructure = {
      plantId,
      sensorTag,
      sensorId,
      plantName,
      sensorName,
      timeFrame: startDate,
    };

    navigate('/Data-Correction', {
      state: { widgetStructure: updatedWidgetStructure },
    });
  };

  const handleMetricSelect = (metricKey: string, sensorId: string) => {
    setDialogOpen(false);
    navigateToDataCorrectionPage(metricKey, sensorId);
  };

  const renderMetricDisplayName = (metricKey: string) => {
    const metric = widgetStructure.metrics[metricKey];
    return metric.sensorTag || metricKey;
  };

  const handleSizeIconClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpenSizeMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleColorIconClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpenThemeMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenSizeMenu(false);
    setOpenThemeMenu(false);
    onCloseHeader();
  };

  const handleSizeChange = (width: number, height: number) => {
    onWidgetSizeChange(width, height);
    handleClose();
    setAllowDrag(true);
  };

  const handleDeleteButtonPress = (event: any) => {
    event.stopPropagation();
    onDeleteButtonPress();
  };

  const { hasPermission: canAccessDataCorrection, loading: loadingDataCorrection } =
    usePermissionCheck(PERMISSION_TAGS.DataCorrection_Manage_WE);

  return (
    <Box
      position="relative"
      // sx={{ width: 'inherit' }}
      sx={{
        width: 'calc(100% - 1px)',
      }}
      onMouseEnter={(event: any) => {
        setAllowDrag(false);
      }}
      onMouseLeave={(event: any) => {
        setAllowDrag(true);
      }}
    >
      <Stack
        direction="row"
        justifyContent="center"
        py={1}
        sx={{
          background: '#F4F5F5',
          position: 'absolute',
          top: 1,
          left: 1,
          width: 'inherit',

          zIndex: '1000',
          borderTopLeftRadius: '15px',
          borderTopRightRadius: '15px',
        }}
      >
        <IconButton onClick={handleDeleteButtonPress}>
          <DeleteIcon sx={{ fontSize: '20px', color: '#ACB3B9' }} />
        </IconButton>
        <IconButton onClick={handleColorIconClick}>
          <FormatColorFillIcon sx={{ fontSize: '20px', color: '#ACB3B9' }} />
        </IconButton>
        <IconButton onClick={handleSizeIconClick}>
          <PhotoSizeSelectSmallIcon sx={{ fontSize: '20px', color: '#ACB3B9' }} />
        </IconButton>
        <IconButton onClick={handleConfigButtonPress}>
          <AppRegistrationIcon sx={{ fontSize: '20px', color: '#ACB3B9' }} />
        </IconButton>
        {canAccessDataCorrection && widgetType === 'graph' && (
          <>
            <IconButton onClick={handleDataCorrectionPress}>
              <QueryStatsIcon sx={{ fontSize: '20px', color: '#ACB3B9' }} />
            </IconButton>

            <CustomDialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
              <Box sx={{ textAlign: 'center', padding: '16px' }}>
                {/* Typography with Center Alignment */}
                <Typography variant="h3" gutterBottom sx={{ textAlign: 'center' }}>
                  Select the Sensor
                </Typography>

                {/* List of Metrics */}
                <List sx={{ marginTop: '16px' }}>
                  {Object.keys(widgetStructure?.metrics).map(metricKey => (
                    <ListItem key={metricKey} sx={{ justifyContent: 'center' }}>
                      <ListItemButton
                        onClick={() =>
                          handleMetricSelect(
                            metricKey,
                            widgetStructure.metrics[metricKey]?.sensorId
                          )
                        }
                        sx={{
                          backgroundColor: '#f5f5f5',
                          borderRadius: '8px',
                          '&:hover': { backgroundColor: '#e0e0e0' },
                          padding: '12px',
                          textAlign: 'center',
                          width: '100%',
                        }}
                      >
                        <ListItemText primary={renderMetricDisplayName(metricKey)} />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>

                {/* Cancel Button */}
                <Button
                  onClick={() => setDialogOpen(false)}
                  sx={{
                    width: '150px',
                    marginTop: '24px',
                    backgroundColor: 'grey',
                    color: 'white',
                    '&:hover': { backgroundColor: '#b71c1c' },
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </CustomDialog>
          </>
        )}
      </Stack>
      <Popover
        id={openSizeMenu ? 'simple-popover' : undefined}
        open={openSizeMenu}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiPopover-paper': {
            border: '1px solid #1836501A',
            borderRadius: '13px',
            paddingLeft: '10px',
            paddingRight: '10px',
            // boxShadow: 'none',
          },
        }}
      >
        <List>
          {WIDGET_SIZE_OPTIONS[widgetType]?.map((option: any) => {
            return (
              <ListItem
                button
                onClick={() => handleSizeChange(option.w, option.h)}
                sx={{ height: '30px', color: '#00000061' }}
              >
                <ListItemText primary={option.label} />
              </ListItem>
            );
          })}
        </List>
      </Popover>
      <Popover
        id={openThemeMenu ? 'simple-popover1' : undefined}
        open={openThemeMenu}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiPopover-paper': {
            border: '1px solid #1836501A',
            borderRadius: '13px',
            paddingLeft: '10px',
            paddingRight: '10px',
            // boxShadow: 'none',
          },
        }}
      >
        <List>
          {Object.keys(WIDGET_THEMES)?.map((option: any) => {
            return (
              <ListItem
                button
                onClick={() => onWidgetThemeChange(option)}
                sx={{ height: '30px', color: '#00000061' }}
              >
                <ListItemText primary={WIDGET_THEMES[option].displayName} />
              </ListItem>
            );
          })}
        </List>
      </Popover>
    </Box>
  );
}
