export default function DeleteOutlinedIcon({
  width = '22',
  height = '22',
  fillColor = '#183650',
  fillInside = 'white',
}: any) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5015 8.8928V18.3349H7.94786V8.8928H15.5015ZM14.0852 3.22754H9.36418L8.41997 4.17175H5.11523V6.06017H18.3342V4.17175H15.0294L14.0852 3.22754ZM17.39 7.00438H6.05944V18.3349C6.05944 19.3735 6.90923 20.2233 7.94786 20.2233H15.5015C16.5402 20.2233 17.39 19.3735 17.39 18.3349V7.00438Z"
        fill={fillColor}
      />
    </svg>
  );
}
