import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface HeaderTitleProps {
  title: string;
}

const styles = {
  container: {
    // padding: '16px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: '36px',
    fontWeight: 'bold',
    color: '#183650',
  },
};

const HeaderTitle: React.FC<HeaderTitleProps> = ({ title }) => {
  return (
    <Box sx={styles.container}>
      <Typography variant="h5" component="h1" sx={styles.title}>
        {title}
      </Typography>
    </Box>
  );
};

export default HeaderTitle;
