import TIME_RANGE from '../constants/constants';
import moment from 'moment';

export const isAbsoluteURL = (link: string) => {
  const absoluteURLRegex = /^(http:\/\/|https:\/\/|ftp:\/\/|www\.[a-z]+)/i;
  return absoluteURLRegex.test(link);
};

export const isRelativeURLWithoutSlash = (link: string) => {
  const relativeURLRegex = /^[^/].*/;
  return relativeURLRegex.test(link);
};

export const formatDate = (date: any) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  const day = String(date.getDate()).padStart(2, '0');

  // Define an array of month names
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const monthIndex = date.getMonth(); // Months are zero-based
  const month = monthNames[monthIndex];

  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

export const formatTime = (date: any) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${hours}:${minutes}`;
};

export const handleDownloadCSV = (data: any) => {
  // Create an array to hold the CSV data
  const csvData = [];

  // Extract and store labels
  const labels = data.datasets.map((obj: any) => obj.label);

  // Insert Date-Time as First column header
  labels.splice(0, 0, 'Date-Time');

  // Add labels as the first row
  csvData.push(labels);

  // Calculate the maximum number of data points among all objects
  const maxDataPoints = Math.max(
    ...data.datasets.map((obj: any) => (Array.isArray(obj.data) ? obj.data.length : 1))
  );

  // Iterate through the data and transform it into the desired CSV format
  for (let i = 0; i < maxDataPoints; i++) {
    const row = data.datasets.map((obj: any) => (Array.isArray(obj.data) ? obj.data[i] : obj.data));
    // Push Date-Time value in first column
    row.splice(0, 0, data.labelsWithYear[i]);
    csvData.push(row);
  }

  // Create a CSV string by joining the rows with line breaks
  const csvString = csvData.map(row => row.join(',')).join('\n');

  // Create a Blob containing the CSV data
  const blob = new Blob([csvString], { type: 'text/csv' });

  // Create a temporary URL to the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a temporary anchor element to trigger the download
  const a = document.createElement('a');
  a.href = url;
  a.download = 'widgetData.csv'; // Set the desired filename

  // Trigger a click event on the anchor element to initiate the download
  a.click();

  // Clean up resources
  window.URL.revokeObjectURL(url);
};

export const startDateTimeRangeNumberWidget = (customTimeRangeSettings: any, timeFrame: any) => {
  if (customTimeRangeSettings?.startTime && customTimeRangeSettings?.endTime) {
    const startTime = new Date(customTimeRangeSettings.startTime);
    return `${formatDateTime(startTime)}`;
  }
  return moment(timeFrame?.startDate?.toString()).format('MMM DD YYYY');
};

export const endDateTimeRangeNumberWidget = (customTimeRangeSettings: any, timeFrame: any) => {
  if (customTimeRangeSettings?.startTime && customTimeRangeSettings?.endTime) {
    const endTime = new Date(customTimeRangeSettings.endTime);
    return `${formatDateTime(endTime)}`;
  }
  return moment(timeFrame?.endDate?.toString()).format('MMM DD YYYY');
};

const formatDateTime = (date: Date) => {
  const dateString = date.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
  });
  const timeString = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  });
  return `${dateString} ${timeString}`;
};

export const handleDownloadCSVForRangeWidget = (
  data: any,
  customTimeRangeSettings: any,
  timeFrame: any
) => {
  // Create an array to hold the CSV data
  const csvData = [];

  // Extract and store labels
  const labels = data.datasets.map((obj: any) => obj.label);

  // Insert Date-Time as First column header
  labels.splice(0, 0, 'Date-Time', 'Percentage Change');

  // Add labels as the first row
  csvData.push(labels);

  // Calculate the maximum number of data points among all objects

  // Iterate through the data and transform it into the desired CSV format
  const row = [data.datasets[0]?.data?.[0]];
  // Push Date-Time value in first column
  row.splice(
    0,
    0,
    `${startDateTimeRangeNumberWidget(customTimeRangeSettings, timeFrame)}-${endDateTimeRangeNumberWidget(customTimeRangeSettings, timeFrame)}`,
    data.datasets[0]?.percentageChange[0]
  );
  csvData.push(row);

  // Create a CSV string by joining the rows with line breaks
  const csvString = csvData?.map(row => row.join(',')).join('\n');

  // Create a Blob containing the CSV data
  const blob = new Blob([csvString], { type: 'text/csv' });

  // Create a temporary URL to the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a temporary anchor element to trigger the download
  const a = document.createElement('a');
  a.href = url;
  a.download = 'widgetData.csv'; // Set the desired filename

  // Trigger a click event on the anchor element to initiate the download
  a.click();

  // Clean up resources
  window.URL.revokeObjectURL(url);
};

export const handleDownloadCSVForManualWidget = (data: any) => {
  // Create an array to hold the CSV data
  const csvData = [];

  // Define the column headers
  const headers = ['Details', 'Plant Name', 'Date Created', 'Type', 'SubType', 'Headers', 'Description', 'Pinned Comment'];
  csvData.push(headers); // Add headers as the first row

  // Iterate over the alert data to extract relevant fields
  data?.forEach((alert: any) => {
    const details = alert?.heading?.eng; // Extract alert heading
    const plantName = alert?.plantName || 'Unknown Plant'; // Extract plant name
    const dateCreated = alert.lastTicket?.createdAt || 'No Date'; // Extract date created
    const type = alert?.issueType?.join(', '); // Extract issue types
    const subType = alert?.subIssueType || 'N/A'; // Extract sub issue type
    const headers =
      alert?.tags?.length > 0 ? alert.tags.map((tag: any) => tag?.name).join(', ') : 'No Tags'; // Extract alert tags
    const desc = alert?.description?.eng || 'N/A';
    const pinnedComment = alert?.tickets[0]?.pinnedComment || 'N/A';
    // Combine all extracted fields into a CSV row
    const row = [details, plantName, dateCreated, type, subType, headers, desc, pinnedComment];
    csvData.push(row); // Add the row to CSV data
  });

  // Create a CSV string by joining the rows with line breaks
  const csvString = csvData.map(row => row.join(',')).join('\n');

  // Create a Blob containing the CSV data
  const blob = new Blob([csvString], { type: 'text/csv' });

  // Create a temporary URL to the Blob
  const url = window.URL.createObjectURL(blob);

  // Create a temporary anchor element to trigger the download
  const a = document.createElement('a');
  a.href = url;
  a.download = 'alertData.csv'; // Set the desired filename

  // Trigger a click event on the anchor element to initiate the download
  a.click();

  // Clean up resources
  window.URL.revokeObjectURL(url);
};

export const calculateTimeFrame = (defaultTimeRange: any) => {
  let {
    unit,
    unitMultiplier,
    timeRangeType,
    // timeRangeInMins,
    startTime,
    endTime,
  } = defaultTimeRange;

  if (!(startTime instanceof Date)) {
    startTime = new Date(startTime);
  }

  if (!(endTime instanceof Date)) {
    endTime = new Date(endTime);
  }

  if (timeRangeType === 'absolute') {
    return { startDate: startTime, endDate: endTime };
  } else if (timeRangeType === 'relative') {
    let timeRange: any, dateRange: any;
    if (['hours', 'minutes'].includes(unit)) {
      timeRange = TIME_RANGE.filter((time: any) => {
        if (time.unit == unit) {
          return time;
        }
      })[0];
      dateRange = timeRange.getDateRange(unitMultiplier);
    } else {
      timeRange = TIME_RANGE.filter((time: any) => {
        if (time.unit == unit && time.value == unitMultiplier) {
          return time;
        }
      })[0];
      dateRange = timeRange.getDateRange();
    }

    const startDate = dateRange[0].$d;
    const endDate = dateRange[1].$d;

    return { startDate, endDate };
  }

  return null;
};
