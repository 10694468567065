export default function CommentOutlinedIcon({
  width = '22',
  height = '22',
  fillColor = '#183650',
}: any) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.158 4.7517C21.158 3.71307 20.3176 2.86328 19.279 2.86328H4.17162C3.13299 2.86328 2.2832 3.71307 2.2832 4.7517V16.0822C2.2832 17.1209 3.13299 17.9706 4.17162 17.9706H17.3906L21.1674 21.7475L21.158 4.7517ZM19.279 4.7517V17.1869L18.1743 16.0822H4.17162V4.7517H19.279ZM6.06004 12.3054H17.3906V14.1938H6.06004V12.3054ZM6.06004 9.47275H17.3906V11.3612H6.06004V9.47275ZM6.06004 6.64012H17.3906V8.52854H6.06004V6.64012Z"
        fill={fillColor}
      />
    </svg>
  );
}
