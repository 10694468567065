export default function SelectionFieldIcon({
  width = '22',
  height = '22',
  fillColor = '#183650',
  fillInside = 'white',
}: any) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.22656 5.43529H5.11498V3.54688C4.07635 3.54688 3.22656 4.39666 3.22656 5.43529ZM3.22656 12.989H5.11498V11.1006H3.22656V12.989ZM7.0034 20.5427H8.89182V18.6542H7.0034V20.5427ZM3.22656 9.21213H5.11498V7.32371H3.22656V9.21213ZM12.6687 3.54688H10.7802V5.43529H12.6687V3.54688ZM18.3339 3.54688V5.43529H20.2223C20.2223 4.39666 19.3726 3.54688 18.3339 3.54688ZM5.11498 20.5427V18.6542H3.22656C3.22656 19.6929 4.07635 20.5427 5.11498 20.5427ZM3.22656 16.7658H5.11498V14.8774H3.22656V16.7658ZM8.89182 3.54688H7.0034V5.43529H8.89182V3.54688ZM10.7802 20.5427H12.6687V18.6542H10.7802V20.5427ZM18.3339 12.989H20.2223V11.1006H18.3339V12.989ZM18.3339 20.5427C19.3726 20.5427 20.2223 19.6929 20.2223 18.6542H18.3339V20.5427ZM18.3339 9.21213H20.2223V7.32371H18.3339V9.21213ZM18.3339 16.7658H20.2223V14.8774H18.3339V16.7658ZM14.5571 20.5427H16.4455V18.6542H14.5571V20.5427ZM14.5571 5.43529H16.4455V3.54688H14.5571V5.43529ZM7.0034 16.7658H16.4455V7.32371H7.0034V16.7658ZM8.89182 9.21213H14.5571V14.8774H8.89182V9.21213Z"
        fill={fillColor}
      />
    </svg>
  );
}
