export default function MoveSelectionIcon({
  width = '22',
  height = '22',
  fillColor = '#183650',
  fillInside = 'white',
}: any) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.94711 9.05173H13.7239V6.2191H16.5566L11.8355 1.49805L7.11448 6.2191H9.94711V9.05173ZM9.0029 9.99594H6.17027V7.16331L1.44922 11.8844L6.17027 16.6054V13.7728H9.0029V9.99594ZM22.2218 11.8844L17.5008 7.16331V9.99594H14.6682V13.7728H17.5008V16.6054L22.2218 11.8844ZM13.7239 14.717H9.94711V17.5496H7.11448L11.8355 22.2707L16.5566 17.5496H13.7239V14.717Z"
        fill={fillColor}
      />
    </svg>
  );
}
