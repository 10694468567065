import { Divider, Stack, ThemeProvider } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import EmailPasswordLogin from "../components/loginComponents/EmailPasswordLogin";
import PersistentUserList from "../components/loginComponents/PersistentUserList";
import logo from "../constants/Images/digitalpaanilogo.png";
import dpTheme from "../styles/theme/dpTheme";
import { getAllCachedLoginEntries } from "../utilities/cachedLoginEntries.utils";

const styles = {
  heading: {
    fontSize: "16px",
    color: "#52687C",
  },
};

export default function LoginV2() {

  return (
    <ThemeProvider theme={dpTheme}>
      <Stack direction="column" alignItems="center" marginTop={"10%"} gap={1}>
        <img src={logo} width={"209px"} alt="ecoinnovision_logo" />
        <div style={styles.heading}>Sign In</div>
      </Stack>
      <Stack
        direction="column"
        alignItems="center"
        gap={5}
        sx={{ marginTop: "50px" }}
      >

        <Stack
          direction={{ xs: "column-reverse", sm: "row" }}
          alignItems="center"
          justifyContent="center"
          gap={{ xs: 3, sm: 5 }}
          divider={<Divider orientation="vertical" flexItem />}
          sx={{ height: "100%" }}
          // border={1}
        >
          {getAllCachedLoginEntries()?.length > 0 ? (
            <PersistentUserList />
          ) : null}
          <EmailPasswordLogin />
        </Stack>
      </Stack>
    </ThemeProvider>
  );
}
