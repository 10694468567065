export default function AddRoadFilledIcon({
  width = '22',
  height = '22',
  fillColor = '#183650',
  fillInside = 'white',
}: any) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.8947 7.9478H12.7831V4.17096H10.8947V7.9478Z" fill={fillColor} />
      <path d="M10.8947 13.6131H12.7831V9.83622H10.8947V13.6131Z" fill={fillColor} />
      <path d="M10.8947 19.2783H12.7831V15.5015H10.8947V19.2783Z" fill={fillColor} />
      <path d="M17.5042 19.2783H19.3926V4.17096H17.5042V19.2783Z" fill={fillColor} />
      <path d="M4.28522 19.2783H6.17364V10.7804H4.28522V19.2783Z" fill={fillColor} />
      <path
        d="M4.28522 6.05938V8.89201H6.17364V6.05938H9.00627V4.17096H6.17364V1.33834H4.28522V4.17096H1.45259V6.05938H4.28522Z"
        fill={fillColor}
      />
    </svg>
  );
}
