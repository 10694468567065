export default function ArrowCircleUpFilledIcon({
  width = '22',
  height = '22',
  fillColor = '#183650',
  fillInside = 'white',
}: any) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7253 19.4382C7.56134 19.4382 4.17162 16.0484 4.17162 11.8845C4.17162 7.72052 7.56134 4.3308 11.7253 4.3308C15.8893 4.3308 19.279 7.72052 19.279 11.8845C19.279 16.0484 15.8893 19.4382 11.7253 19.4382ZM11.7253 21.3266C16.9373 21.3266 21.1674 17.0965 21.1674 11.8845C21.1674 6.67244 16.9373 2.44238 11.7253 2.44238C6.51326 2.44238 2.2832 6.67244 2.2832 11.8845C2.2832 17.0965 6.51326 21.3266 11.7253 21.3266ZM10.7811 11.8845V15.6613H12.6695V11.8845H15.5021L11.7253 8.10764L7.94846 11.8845H10.7811Z"
        fill={fillColor}
      />
    </svg>
  );
}
