export default function ReplacePointIcon({
  width = '22',
  height = '22',
  fillColor = '#183650',
  fillInside = 'white',
}: any) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7253 2.2832C6.51326 2.2832 2.2832 6.51326 2.2832 11.7253C2.2832 16.9373 6.51326 21.1674 11.7253 21.1674C16.9373 21.1674 21.1674 16.9373 21.1674 11.7253C21.1674 6.51326 16.9373 2.2832 11.7253 2.2832ZM11.7253 19.279C7.56134 19.279 4.17162 15.8893 4.17162 11.7253C4.17162 7.56134 7.56134 4.17162 11.7253 4.17162C15.8893 4.17162 19.279 7.56134 19.279 11.7253C19.279 15.8893 15.8893 19.279 11.7253 19.279ZM15.6627 14.3313L14.624 13.2927C15.2944 12.0369 15.1245 10.4506 14.0669 9.3931C13.4154 8.7416 12.5751 8.42057 11.7253 8.42057C11.697 8.42057 11.6686 8.43001 11.6403 8.43001L12.6695 9.4592L11.6686 10.4601L8.99654 7.78795L11.6686 5.11583L12.6695 6.1167L11.7631 7.02314C12.9622 7.03258 14.1519 7.47636 15.0678 8.3828C16.673 9.9974 16.8712 12.4996 15.6627 14.3313ZM14.4541 15.6627L11.782 18.3348L10.7811 17.3339L11.6781 16.4369C10.4884 16.4275 9.29868 15.9648 8.39224 15.0584C6.77764 13.4438 6.57936 10.9511 7.78795 9.11928L8.82658 10.1579C8.15619 11.4137 8.32615 13 9.38366 14.0575C10.0446 14.7184 10.9227 15.0395 11.8008 15.0112L10.7811 13.9914L11.782 12.9905L14.4541 15.6627Z"
        fill={fillColor}
      />
    </svg>
  );
}
