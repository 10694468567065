import {
  Box,
  Stack,
  Divider,
  Popover,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import moment from 'moment';
import { WIDGET_THEMES } from '../../../constants/widgetConfig.constants';
import Chip from '../../../core-components/theme/components/Chips/PrimaryChips';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HistoryIcon from '@mui/icons-material/History';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useEffect, useState } from 'react';
import CommentSection from './CommentSection';
import { getAllTicketIdsOfAlert } from '../../../services/tickets';
import { Neutrals } from '../../../core-components/theme/color-palette/colors';
import HistoryPopupBox from './HistoryPopupBox';
import { getTicketData } from '../../../services/tickets';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import MarkChatUnreadOutlinedIcon from '@mui/icons-material/MarkChatUnreadOutlined';

export default function ManualEntryRow({ alert, theme, refreshRow }: any) {
  const fontColor = WIDGET_THEMES[theme || 'default'].fontColor;
  const background = WIDGET_THEMES[theme || 'default'].backgroundColor;
  const lightBackground = WIDGET_THEMES[theme || 'default'].lightBackgroundColor;
  const lightBorder = WIDGET_THEMES[theme || 'default'].lightBorderColor;
  const [openCommentSection, setOpenCommentSection] = useState(false);
  const [ticketIds, setTicketIds] = useState<string[]>([]);
  const [openHistoryPopupBox, setOpenHistoryPopupBox] = useState(false);
  const [pinnedComment, setPinnedComment] = useState<any>(null);
  const [refreshLastTicket, setRefreshLastTicket] = useState(false);
  const [numberOfComments, setNumberOfComments] = useState(0);

  useEffect(() => {}, [refreshRow]);

  useEffect(() => {
    getAllTicketIdsOfAlert(alert._id, '').then(data => {
      setTicketIds(data);
    });
  }, []);

  useEffect(() => {
    if (ticketIds?.length > 0) {
      getTicketData(ticketIds[ticketIds?.length - 1], '').then((data: any) => {
        let foundComment = data.events.find(
          (event: any) => event.pinned && event.event == 'comment'
        );
        if (foundComment) {
          setPinnedComment(foundComment);
        } else {
          setPinnedComment(null);
        }
        let countComments = data.events.reduce(
          (total: number, currentEvent: any) =>
            currentEvent.event == 'comment' ? total + 1 : total,
          0
        );
        // console.log('countComments :', countComments);
        setNumberOfComments(countComments);
      });
    }
  }, [ticketIds, refreshLastTicket]);

  // State for Popover
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // Handle popover open/close
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getPriorityIcon = (priority: string) => {
    if (priority == 'Low') return <KeyboardArrowDownIcon sx={{ color: 'red' }} />;
    if (priority == 'High') return <KeyboardArrowUpIcon sx={{ color: 'red' }} />;
    if (priority == 'Medium') return <DragHandleIcon sx={{ color: 'red', width: '18px' }} />;
    else return null;
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box sx={{ boxSizing: 'border-box' }}>
      <Box
        border={`1px solid ${background}`}
        borderRadius={2}
        sx={{
          overflow: 'hidden',
        }}
      >
        <Stack direction="row">
          <Stack direction="column" gap={2} sx={{ width: '57%' }} p={2}>
            <Box>
              <Stack direction="row" alignItems="center" sx={{ width: '100%' }} gap={1}>
                <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
                  <Box sx={{ color: fontColor, fontWeight: 500, fontSize: '12px' }}>
                    {alert.heading?.eng}
                  </Box>
                  {!!alert.description?.eng && (
                    <Box>
                      <Tooltip title={alert.description?.eng}>
                        <InfoOutlinedIcon
                          sx={{ color: fontColor, fontSize: '12px', marginLeft: '3px' }}
                        />
                      </Tooltip>
                    </Box>
                  )}

                  <Box sx={{ marginLeft: 'auto' }}>
                    <Stack direction="row" alignItems={'center'} gap={1}>
                      <Box>{getPriorityIcon(alert.priority)}</Box>
                      {numberOfComments > 0 && (
                        <MarkChatUnreadOutlinedIcon sx={{ color: fontColor, fontSize: '15px' }} />
                      )}
                    </Stack>
                  </Box>
                </Stack>
              </Stack>
            </Box>
            <Box sx={{ color: fontColor, fontWeight: 400, fontSize: '14px' }}>
              {pinnedComment?.comment}
            </Box>
          </Stack>
          <Divider orientation="vertical" flexItem sx={{ background: lightBorder }} />
          <Box sx={{ width: '10%', background: lightBackground }} p={1}>
            <Stack direction="column">
              <Box sx={{ fontSize: '12px', fontWeight: 500, color: fontColor }}>
                {alert?.plantName}
              </Box>
              <Box sx={{ fontSize: '10px' }}>
                {alert?.lastTicket?.createdAt
                  ? moment(alert?.lastTicket?.createdAt, 'YYYY-MM-DDTHH:mm:ss.SSS')?.format(
                      'DD/MM/YYYY hh:mm a'
                    )
                  : '-'}
              </Box>
            </Stack>
          </Box>
          <Divider orientation="vertical" flexItem sx={{ background: lightBorder }} />
          <Box sx={{ width: '30%', background: lightBackground }} p={2}>
            <Stack direction="column" gap={2}>
              <Box sx={{ color: fontColor, fontWeight: 500, fontSize: '12px' }}>
                {`Plant process issues (${alert.issueType?.map((type: any) => {
                  return type;
                })} )`}
              </Box>
              <Stack direction="row" gap={1}>
                {alert.tags?.map((tag: any, index: number) => {
                  return (
                    <Chip
                      key={index}
                      label={tag?.name}
                      variant="outlined"
                      size="small"
                      sx={{
                        fontSize: '10px',
                        color: fontColor,
                        borderColor: background,
                      }}
                    />
                  );
                })}
                {alert.issueType == null || alert.issueType?.length < 1 ? (
                  <Chip
                    label="None"
                    variant="outlined"
                    sx={{
                      fontSize: '9px',
                      color: fontColor,
                      borderColor: background,
                    }}
                  />
                ) : null}
              </Stack>
            </Stack>
          </Box>
          <Divider orientation="vertical" flexItem sx={{ background: lightBorder }} />
          <Box sx={{ width: '3%', background: lightBackground }} p={1}>
            {/* IconButton for vertical ellipsis */}

            <MoreVertIcon
              sx={{
                color: fontColor,
                cursor: 'pointer', // This changes the cursor to a pointer on hover
              }}
              onClick={(event: any) => {
                handleClick(event);
              }}
            />

            {/* Popover for more options */}
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem
                sx={{ color: Neutrals[500] }}
                disabled
                onClick={() => console.log('Edit clicked')}
              >
                <ListItemIcon>
                  <EditOutlinedIcon sx={{ width: '20px', height: '20px', color: Neutrals[300] }} />
                </ListItemIcon>
                <ListItemText
                  primary="Edit"
                  sx={{
                    '& .MuiListItemText-primary': {
                      fontSize: '14px', // Adjust the font size here
                    },
                  }}
                />
              </MenuItem>
              <MenuItem
                sx={{ color: Neutrals[500] }}
                onClick={() => {
                  setOpenCommentSection(true);
                  setAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <CommentOutlinedIcon
                    sx={{ width: '20px', height: '20px', color: Neutrals[300] }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Comment"
                  sx={{
                    '& .MuiListItemText-primary': {
                      fontSize: '14px', // Adjust the font size here
                    },
                  }}
                />
              </MenuItem>
              <MenuItem
                sx={{ color: Neutrals[500] }}
                onClick={() => {
                  setOpenHistoryPopupBox(true);
                  setAnchorEl(null);
                }}
              >
                <ListItemIcon>
                  <HistoryIcon sx={{ width: '20px', height: '20px', color: Neutrals[300] }} />
                </ListItemIcon>
                <ListItemText
                  primary="History"
                  sx={{
                    '& .MuiListItemText-primary': {
                      fontSize: '14px', // Adjust the font size here
                    },
                  }}
                />
              </MenuItem>
            </Popover>
          </Box>
        </Stack>
      </Box>
      <CommentSection
        open={openCommentSection}
        handleClose={() => {
          setOpenCommentSection(false);
        }}
        alert={alert}
        ticketId={ticketIds[0]}
        setRefreshLastTicket={setRefreshLastTicket}
      />
      <HistoryPopupBox
        open={openHistoryPopupBox}
        handleClose={() => {
          setOpenHistoryPopupBox(false);
        }}
        alert={alert}
      />
    </Box>
  );
}
