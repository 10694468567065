import axios from 'axios';
import axiosInstance from '../constants/axiosInstance';
import { timeFrame } from '../Interfaces/dashboardPageInterfaces';
import { add530Hours } from '../utils/dashboard.utils';

const getPageDropdownListForUser = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/dashboard/getPageDropdownListForUser?plantId=${plantId}`
    );
    return data.pageDropdownList;
  } catch (error) {}
};

const getPdfForDashboardPage = async (
  plantId: string,
  pageId: string | null,
  timeFrame: timeFrame | null,
  printFormat: string
) => {
  try {
    const { data } = await axiosInstance().post(
      `/dashboard/getPdfForDashboardPage?plantId=${plantId}&pageId=${pageId}`,
      { timeFrame: timeFrame, printFormat: printFormat }
    );
    return data.pdf;
  } catch (error) {}
};

const getWidgetDataFromDb = async (plantId: string, widgetStructure: any, controller: any) => {
  try {
    const { data } = await axiosInstance().post(
      `/dashboard/getWidgetData?plantId=${plantId}`,
      widgetStructure,
      {
        cancelToken: new axios.CancelToken(c => {
          controller.signal.addEventListener('abort', () => c());
        }),
      }
    );
    return data?.widgetData;
  } catch (err) {}
};

const getDashboardClusters = async (plantId: string, userId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/newdashboard/dashboardClusters?plantId=${plantId}&userId=${userId}`
    );
    return data?.data?.dashboardClusters;
  } catch (err) {}
};

const getDashboardPageData = async (pageId: string, forView: string = 'dashboard') => {
  try {
    const { data } = await axiosInstance().post(
      `/newDashboard/getDashboardPageData?pageId=${pageId}&&forView=${forView}`
    );
    return data?.data;
  } catch (err) {}
};

const getWidgetData = async ({
  plantId,
  widgetId,
  parent = 'dashboard',
  widgetStructure,
  startDate,
  endDate,
  granularity,
  timeRangeType,
  clock,
  selectedChart,
  widgetType,
}: any) => {
  try {
    let resp;
    if (!widgetId) {
      resp = await axiosInstance().post(
        `/newDashboard/getWidgetData?plantId=${plantId}&startDate=${startDate}&endDate=${endDate}`,
        { widgetStructure }
      );
    } else {
      resp = await axiosInstance().post(
        `/newDashboard/getWidgetData?plantId=${plantId}&widgetId=${widgetId}&startDate=${startDate}&endDate=${endDate}&granularity=${granularity}&timeRangeType=${timeRangeType}&parent=${parent}&clock=${clock}&selectedChart=${selectedChart}&widgetType=${widgetType}`
      );
    }
    return resp.data?.data;
  } catch (err) {
    console.error('ERROR | getWidgetData', err);
  }
};
   
const saveWidgetData = async (data: any) => {
  try {
    const { startDate : startTime, endDate : endTime } = add530Hours(data?.selectedTimeRange?.startTime, data?.selectedTimeRange?.endTime);
    data.selectedTimeRange = {
    ...data.selectedTimeRange,
     startTime,
     endTime, 
    };
    const resp = await axiosInstance().post('/newDashboard/updateWidgetTimeSettings', data);
    return resp.data.data;
  } catch (err) {
    console.error("Error saving widget data:", err);
  }
};

const saveDashboardData = async (data: any) => {
  try {
    const resp = await axiosInstance().post(`/newDashboard/updateDashboardTimeSettings`, data);
    return resp.data.data;
  } catch (err) {}
};

const createNewDashboard = async (data: any) => {
  try {
    const resp = await axiosInstance().post(`/newDashboard/createNewDashboardPage`, data);
    return resp.data?.data;
  } catch (err) {}
};

const getDashboardClustersForClient = async () => {
  try {
    const { data } = await axiosInstance().post(`/newdashboard/unfiedDashboardClusters`);
    return data?.data?.dashboardClusters;
  } catch (err) {}
};

const createNewUnifiedDashboard = async (data: any) => {
  try {
    const resp = await axiosInstance().post(`/newDashboard/createNewUnifiedDashboardPage`, data);
    return resp.data?.data;
  } catch (err) {}
};

const getPdfForUnifiedDashboardPage = async (
  pageId: string | null,
  timeFrame: timeFrame | null,
  printFormat: string
) => {
  try {
    const { data } = await axiosInstance().post(
      `/dashboard/getPdfForUnifiedDashboardPage?plantId=null&pageId=${pageId}`,
      { timeFrame: timeFrame, printFormat: printFormat }
    );
    return data.pdf;
  } catch (error) {}
};

const migrateSinglePlantDashboardPageToUnifiedDashboardForSingleUser = async () => {
  try {
    const { data } = await axiosInstance().post(
      `/newDashboard/migrateSinglePlantDashboardPageToUnifiedDashboardForSingleUser`
    );
    return data?.data;
  } catch (err) {
    throw err;
  }
};

const showMigrationOptionForUnifiedDashboard = async () => {
  try {
    const { data } = await axiosInstance().post(
      `/newDashboard/showMigrationOptionForUnifiedDashboard`
    );
    return data?.data;
  } catch (err) {
    console.log(err);
  }
};

const getMapWidgetData = async (
  widgetId: string | null,
  timeFrame: any,
  filterName: string,
  widgetStructure = {}
) => {
  try {
    const { data } = await axiosInstance().post(
      `/newdashboard/getMapWidgetData?widgetId=${widgetId}&filterName=${filterName}`,
      { timeFrame, widgetStructure, filterName }
    );
    return data?.data;
  } catch (err) {}
};

const getMapWidgetFiltersList = async (widgetId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/newdashboard/getMapWidgetFiltersList?widgetId=${widgetId}`
    );
    return data?.data;
  } catch (err) {}
};

const getSankeyWidgetData = async (
  widgetId: string | null,
  timeFrame: any,
  widgetStructure: any = {}
) => {
  try {
    const { data } = await axiosInstance().post(
      `/newdashboard/getSankeyWidgetData?widgetId=${widgetId}`,
      { timeFrame, widgetStructure }
    );
    return data?.data;
  } catch (err) {}
};

const getShareDashboardData = async (pageId: string | null) => {
  try {
    const resp = await axiosInstance().post(
      `/newDashboard/getPreShareDashboardDialogueBox?pageId=${pageId}`
    );
    return resp?.data?.data;
  } catch (error) {
    throw error;
  }
};

const saveShareDashboardData = async (
  data: { usersPermissions: any[]; pageId: string } // Adjusted type definition
) => {
  try {
    const response = await axiosInstance().post('/newDashboard/saveShareDashboard', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export {
  createNewDashboard, createNewUnifiedDashboard, getDashboardClusters, getDashboardClustersForClient, getDashboardPageData, getMapWidgetData,
  getMapWidgetFiltersList, getPageDropdownListForUser,
  getPdfForDashboardPage, getPdfForUnifiedDashboardPage, getSankeyWidgetData, getShareDashboardData, getWidgetData, getWidgetDataFromDb, migrateSinglePlantDashboardPageToUnifiedDashboardForSingleUser, saveDashboardData, saveShareDashboardData, saveWidgetData, showMigrationOptionForUnifiedDashboard
};

