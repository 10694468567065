import { Box } from '@mui/material';
import { useState } from 'react';
import DeleteConfirmation from '../../globalComponents/dialogs/DeleteConfirmation';
import GeneralisedWidgetConfigHeader from '../GeneralisedWidgetComponents/subComponents/generalisedWidgetConfigHeader';
import ManualEntryWidget from './ManualEntryWidget';
import ManualEntryConfigPopup from './subComponents/ManaulEntryConfigPopup';

const currentTime = new Date();
const midnight = new Date();
midnight.setHours(0, 0, 0, 0);

export function ManualEntryWidgetConfig({
  widgetId,
  widget,
  widgetClusterIndex,
  widgetIndex,
  handleWidgetSizeChange,
  changeWidgetTheme,
  deleteCustomWidget,
}: any) {
  const [showConfigHeader, setShowConfigHeader] = useState(false);
  //@ts-ignore
  const [openWidgetConfig, setOpenWidgetConfig] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [refreshStandAloneWidget, setRefreshStandAloneWidget] = useState(false);
  const [processing, setProcessing] = useState(false);
  const timeFrame = {
    startDate: midnight,
    endDate: currentTime,
  }; 
  const onWidgetSizeChange = (width: number, height: number) => {
    handleWidgetSizeChange(widgetClusterIndex, widgetIndex, width, height);
  };

  const onWidgetThemeChange = (theme: any) => {
    changeWidgetTheme(widgetClusterIndex, widgetIndex, theme);
    setRefreshStandAloneWidget(!refreshStandAloneWidget);
    console.log('button press 123', theme);
  };

  return (
    <Box
      sx={{ width: '100%', height: '100%' }}
      onMouseEnter={() => {
        setShowConfigHeader(true);
      }}
      onMouseLeave={() => {
        setShowConfigHeader(false);
      }}
    >
      {showConfigHeader && (
        <GeneralisedWidgetConfigHeader
          onConfigButtonPress={() => {
            setOpenWidgetConfig(true);
            setShowConfigHeader(false);
          }}
          onWidgetSizeChange={onWidgetSizeChange}
          widgetType={widget.widgetType}
          onDeleteButtonPress={() => {
            setShowDeleteModal(true);
          }}
          onWidgetThemeChange={onWidgetThemeChange}
          onCloseHeader={() => {
            setShowConfigHeader(false);
          }}
        />
      )}
      <ManualEntryWidget
        widgetStructure={widget}
        widgetId={widgetId}
        refresh={refreshStandAloneWidget}
        timeFrame={timeFrame}
        enableNewToolBar={false}
      />
      <DeleteConfirmation
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        alertMessage="Are you sure you want to delete this widget? This action is irreversible!"
        processing={processing}
        setProcessing={setProcessing}
        onConfirmAction={() => {
          deleteCustomWidget(widgetClusterIndex, widgetIndex);
        }}
      />
      <ManualEntryConfigPopup
        open={openWidgetConfig}
        handleClose={() => {
          setOpenWidgetConfig(false);
        }}
        widgetConfig={widget}
        setRefresh={setRefreshStandAloneWidget}
      />
    </Box>
  );
}
