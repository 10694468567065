import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import DialogBox from '../../core-components/theme/components/DialogBox/DialogBox';
import ConfirmationDialog from '../../core-components/theme/components/DialogBox/ConfirmationDialog';
import ArrowCircledownfilledIcon from '../../core-components/assets/icons/ArrowCircleDownFilledIcon';
import ArrowCircleUpFilledIcon from '../../core-components/assets/icons/ArrowCircleUpFilledIcon';
import ReplacePointIcon from '../../core-components/assets/icons/ReplacePointIcon';
import DeleteOutlinedIcon from '../../core-components/assets/icons/DeleteOutlinedIcon';
import MoveSelectionIcon from '../../core-components/assets/icons/MoveSelectionIcon';
import ResetIcon from '../../core-components/assets/icons/ResetIcon';
import CommentOutlinedIcon from '../../core-components/assets/icons/CommentOutlinedIcon';
import AddRoadFilledIcon from '../../core-components/assets/icons/AddRoadFilledIcon';
import SelectionFieldIcon from '../../core-components/assets/icons/SelectionFieldIcon';
import RemoveRoadFilledIcon from '../../core-components/assets/icons/RemoveRoadFilledIcon';
import InputAdornments from '../../core-components/theme/components/Inputs/NumberInput';
import { Stack, Typography } from '@mui/material';
import { Primary } from '../../core-components/theme/color-palette/colors';

const styles = {
  buttonStyle: {
    // minWidth:"0%"  ,
    width: '4.5vw',
    border: '1px solid #ACB3B9',
    margin: '8px',
    padding: { xs: '15px', md: '0' },
    borderRadius: '8px',
  },
  iconStyle: {
    rootWidth: '24px',
    rootHeight: '24px',
    fillColor: '#5D666E',
    fillInside: Primary[50],
    fillColorSelected: '#183650',
    fillColorDisabled: '#ACB3B9',
    fillBackgroundColorDisabled: 'white',
  },
  gridContainer: {
    width: 'auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)', // Default for large screens
    '@media (max-width: 906px)': {
      gridTemplateColumns: 'repeat(2, 1fr)', // Adjust for medium screens
    },
    '@media (max-width: 766px)': {
      gridTemplateColumns: 'repeat(5, 1fr)', // Adjust for medium screens
    },
    '@media (max-width: 395px)': {
      gridTemplateColumns: 'repeat(4, 1fr)', // Adjust for small screens
    },
    maxWidth: {
      // xs: '18%',
      md: '12%',
    },
  },
};

const buttonConfigs = [
  { label: 'Move Selection', Icon: MoveSelectionIcon, handler: 'handleMoveSelectionClick' },
  { label: 'Set Value', Icon: ReplacePointIcon, handler: 'handleIconAiClick', dialogTrigger: true },
  { label: 'Selection Field', Icon: SelectionFieldIcon, handler: 'handleSelectionFieldClick' },
  { label: 'Comment', Icon: CommentOutlinedIcon, handler: 'handleCommentClick' },
  {
    label: 'Increment Value',
    Icon: ArrowCircleUpFilledIcon,
    handler: 'handleArrowUpClick',
    dialogTrigger: true,
  },
  {
    label: 'Add offSet',
    Icon: AddRoadFilledIcon,
    handler: 'handleAddRoadClick',
    dialogTrigger: true,
  },
  {
    label: 'Decrement Value',
    Icon: ArrowCircledownfilledIcon,
    handler: 'handleArrowDownClick',
    dialogTrigger: true,
  },
  {
    label: 'Remove offSet',
    Icon: RemoveRoadFilledIcon,
    handler: 'handleRemoveRoadClick',
    dialogTrigger: true,
  },
  { label: 'Delete', Icon: DeleteOutlinedIcon, handler: 'handleDeleteClick' },
  { label: 'Reset Graph', Icon: ResetIcon, handler: 'handleResetClick' },
];

interface ActionButtonsPanelProps {
  onMoveSelectionClick: () => void;
  isEditModeEnabled: boolean;
  selectedDataPoint: boolean;
  onIncrementDataPoint: (value: number) => void;
  onAddRoad: (value: number) => void;
  onAiSetDataPoint: (value: number) => void;
  onDeleteDataPoint: (index: any, isDeleteConfirm: boolean) => void; // Callback to delete data point
  setDeleteConfirm: (isConfirm: boolean) => void; // New prop to update delete confirmation state
  onSelectionFieldClick: (selectionMode: boolean) => void; // Callback to toggle selection mode
  handleReset: any;
}

const ActionButtonsPanel: React.FC<ActionButtonsPanelProps> = ({
  onMoveSelectionClick,
  isEditModeEnabled,
  selectedDataPoint,
  onIncrementDataPoint,
  onAddRoad,
  onAiSetDataPoint,
  onDeleteDataPoint,
  setDeleteConfirm,
  onSelectionFieldClick,
  handleReset,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [deletionMode, setDeletionMode] = useState(false);
  const [incrementValue, setIncrementValue] = useState<number>(0);
  const [unit, setUnit] = useState<string>('+');
  const [activeHandler, setActiveHandler] = useState<string | null>(null);
  const [selectionMode, setSelectionMode] = useState(false);

  const resetIncrementValue = () => {
    setIncrementValue(0);
  };

  const handleDialogSubmit = () => {
    const adjustedValue = unit === '-' ? -incrementValue : incrementValue;

    if (activeHandler === 'handleAddRoadClick' && unit === '+') {
      onAddRoad(adjustedValue);
    } else if (activeHandler === 'handleRemoveRoadClick' && unit === '-') {
      onAddRoad(adjustedValue);
    } else if (activeHandler === 'handleIconAiClick' && unit === '=') {
      onAiSetDataPoint(adjustedValue);
    } else {
      onIncrementDataPoint(adjustedValue);
    }

    setOpenDialog(false);
    setActiveHandler(null);
  };

  const handleClick = (handler: string, dialogTrigger = false) => {
    setActiveHandler(handler);

    switch (handler) {
      case 'handleMoveSelectionClick':
        onMoveSelectionClick();
        break;
      case 'handleResetClick':
        handleReset();
        break;
      case 'handleArrowUpClick':
        setUnit('+');
        if (dialogTrigger) {
          resetIncrementValue(); // Reset Graph incrementValue
          setOpenDialog(true);
        }
        break;
      case 'handleArrowDownClick':
        setUnit('-');
        if (dialogTrigger) {
          resetIncrementValue(); // Reset Graph incrementValue
          setOpenDialog(true);
        }
        break;
      case 'handleAddRoadClick':
        setUnit('+');
        if (dialogTrigger) {
          resetIncrementValue(); // Reset Graph incrementValue
          setOpenDialog(true);
        }
        break;
      case 'handleRemoveRoadClick':
        setUnit('-');
        if (dialogTrigger) {
          resetIncrementValue(); // Reset Graph incrementValue
          setOpenDialog(true);
        }
        break;
      case 'handleIconAiClick':
        setUnit('=');
        if (dialogTrigger) {
          resetIncrementValue(); // Reset Graph incrementValue
          setOpenDialog(true);
        }
        break;

      case 'handleDeleteClick':
        setDeletionMode(prev => !prev);
        setOpenConfirmationDialog(true);
        break;
      case 'handleSelectionFieldClick':
        setSelectionMode(prev => !prev); // Toggle selection mode
        onSelectionFieldClick(!selectionMode);
        break;
    }
  };

  const handleDeletePointConfirm = () => {
    if (typeof selectedDataPoint === 'boolean') {
      onDeleteDataPoint(selectedDataPoint, true);
      setDeleteConfirm(true);
      setTimeout(() => setDeleteConfirm(false), 0);
      setDeletionMode(false);
      setOpenConfirmationDialog(false);
    }
  };

  return (
    <>
      <Grid container spacing={0} sx={styles.gridContainer} className={'dataTrigger'}>
        {buttonConfigs.map(({ label, Icon, handler, dialogTrigger }) => (
          <Tooltip key={label} title={label}>
            <Button
              sx={styles.buttonStyle}
              onClick={() => handleClick(handler, dialogTrigger)}
              style={
                label === 'Move Selection' && isEditModeEnabled
                  ? { backgroundColor: Primary[100], border: `1px solid ${Primary[300]}` }
                  : label === 'Delete' && deletionMode
                    ? { backgroundColor: Primary[100], border: `1px solid ${Primary[300]}` }
                    : label === 'Selection Field' && selectionMode
                      ? { backgroundColor: Primary[100], border: `1px solid ${Primary[300]}` }
                      : {}
              }
              disabled={
                label === 'Selection Field' ||
                label === 'Comment' || // Always disabled
                ((label === 'Increment Value' ||
                  label === 'Decrement Value' ||
                  label === 'Move Selection' ||
                  label === 'Add offSet' ||
                  label === 'Delete' ||
                  label === 'Remove offSet' ||
                  label === 'Reset Graph' ||
                  label === 'Set Value') &&
                  !selectedDataPoint)
              }
            >
              <Icon
                width={styles.iconStyle.rootWidth}
                height={styles.iconStyle.rootHeight}
                fillColor={
                  (label === 'Increment Value' ||
                    label === 'Move Selection' ||
                    label === 'Decrement Value' ||
                    label === 'Add offSet' ||
                    label === 'Remove offSet' ||
                    label === 'Set Value' ||
                    label === 'Selection Field' ||
                    label === 'Comment' ||
                    label === 'Reset Graph' ||
                    label === 'Delete') &&
                  !selectedDataPoint
                    ? styles.iconStyle.fillColorDisabled
                    : label === 'Move Selection' && isEditModeEnabled
                      ? styles.iconStyle.fillColorSelected
                      : label === 'Selection Field' && selectionMode
                        ? styles.iconStyle.fillColorSelected
                        : styles.iconStyle.fillColor
                }
                fillInside={
                  (label === 'Increment Value' ||
                    label === 'Decrement Value' ||
                    label === 'Move Selection' ||
                    label === 'Add offSet' ||
                    label === 'Remove offSet' ||
                    label === 'Set Value' ||
                    label === 'Selection Field' ||
                    label === 'Comment' ||
                    label === 'Reset Graph' ||
                    label === 'Delete') &&
                  !selectedDataPoint
                    ? styles.iconStyle.fillBackgroundColorDisabled
                    : label === 'Move Selection' && isEditModeEnabled
                      ? styles.iconStyle.fillInside
                      : label === 'Selection Field' && selectionMode
                        ? styles.iconStyle.fillInside
                        : undefined
                }
              />
            </Button>
          </Tooltip>
        ))}
      </Grid>

      <DialogBox
        TitlefontSize="28px"
        open={openDialog}
        title="Enter Value"
        handleClose={() => setOpenDialog(false)}
      >
        <InputAdornments
          unitProvided={unit}
          placeholder="Enter a number"
          value={incrementValue.toString()}
          onChange={(e: any) => setIncrementValue(Number(e.target.value))}
        />

        <Stack sx={{ width: '100%', mt: 3 }} spacing={2}>
          <Grid container>
            <Grid item xs={6} padding={1}>
              <Button
                onClick={() => setOpenDialog(false)}
                fullWidth
                sx={{
                  backgroundColor: 'white',
                  color: 'grey',
                  border: '1px solid #ccc',
                  '&:hover': {
                    backgroundColor: '#f5f5f5',
                  },
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6} padding={1}>
              <Button
                onClick={handleDialogSubmit}
                fullWidth
                sx={{
                  backgroundColor: Primary[500],
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </DialogBox>

      <ConfirmationDialog
        open={openConfirmationDialog}
        title={
          <Typography fontSize={'16px'}>Do you want to delete the selected Datapoint?</Typography>
        }
        description={<Typography align="center">This action cannot be undone.</Typography>}
        actions={[
          {
            text: 'Cancel',
            color: '#E0E0E0',
            textColor: '#000',
            handler: () => setOpenConfirmationDialog(false),
          },
          {
            text: 'Delete',
            color: '#FF0000',
            textColor: '#FFF',
            handler: handleDeletePointConfirm,
          },
        ]}
        handleClose={() => {
          setOpenConfirmationDialog(false);
          setDeletionMode(false);
        }}
        showCloseIcon={false}
      />
    </>
  );
};

export default ActionButtonsPanel;
