import { Primary } from '../../../core-components/theme/color-palette/colors';

const breakpoints = {
  mobile: '@media (max-width: 767px)',
  tablet: '@media (min-width: 768px) and (max-width: 1024px)',
};

// console.log("for styling purposes only")
const DataCorrectionStyle = {
  LayoutStyle: {
    '@media (max-width: 1000px)': {
      height: '100%',
    },

    height: '100vh',
    background: '#F5F6F9',
    padding: '20px 24px',
    [breakpoints.tablet]: { padding: '12px 10px' },
    [breakpoints.mobile]: { padding: '0px' },
  },
  saveCancelContainer: { marginTop: '0px' },
  cancelButtonCustom: {
    borderColor: '#CCC',
    width: { xs: '100%', sm: '50%', md: '12%', xl: '12%' },
  },
  saveButtonCustom: {
    backgroundColor: Primary[500],
    width: { xs: '100%', sm: '50%', md: '12%', xl: '12%' },
    '&:hover': {
      backgroundColor: Primary[700],
    },
  },
  filterSection: { marginBottom: '16px' },
  mainGridContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: '16px',
    [breakpoints.tablet]: { gridTemplateColumns: '1fr' },
    [breakpoints.mobile]: { gridTemplateColumns: '1fr' },
  },
  graphAndActionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    gap: '16px',
    [breakpoints.mobile]: {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },
  saveCancelButtons: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    [breakpoints.mobile]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px',
    },
  },
};

export default DataCorrectionStyle;
