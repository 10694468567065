import { Dayjs } from 'dayjs';
import axiosInstance from '../../constants/axiosInstance';
import { add530Hours } from '../../utils/dashboard.utils';

const getWorkspaceDataforDataCorrection = async () => {
  try {
    const { data } = await axiosInstance().post('/workspace/getListOfWorkspacesForClient');
    return data?.data?.map((workspace: any) => ({
      _id: workspace._id,
      name: workspace.name,
      assetIds: workspace.assetIds[0],
    }));
  } catch (err) {
    throw err;
  }
};

const getPlantDataforDataCorrection = async (workspaceId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/plants/fetchPlantListByWorkspaceId?workspaceId=${workspaceId}`
    );
    return (
      data?.body?.map((plant: any) => ({
        _id: plant._id,
        name: plant.plantName,
      })) || []
    ); // Return an empty array if data.body is undefined
  } catch (err) {
    return []; // Return an empty array on error
  }
};

const getSensorDataforDataCorrection = async (plantId: string, query: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/sensors/getSensorListByPlantIdForDC?plantId=${plantId}&regExQuery=${query}`
    );

    const dataFiltered =
      data?.map((sensor: any) => ({
        sensorId: sensor._id,
        sensorTag: sensor.sensorTag,
        sensorName: sensor.sensorName,
      })) || [];
    return dataFiltered; // Return an empty array if data is undefined
  } catch (err) {
    return []; // Return an empty array on error
  }
};

const fetchLineGraphData = async (payload: {
  plantId: string | undefined;
  sensorId: string | undefined;
  fromDate: Dayjs | null;
}) => {
  try {
    const formattedPayload = {
      ...payload,
      fromDate: payload.fromDate
        ? new Date(payload.fromDate as unknown as Date).getTime() + 1000 * 60 * 60 * 5.5
        : '',
    };

    const plantId = formattedPayload.plantId;
    const sensorId = formattedPayload.sensorId;
    const startDate = formattedPayload.fromDate;

    const { data } = await axiosInstance().post(
      `/sensors/getDataCorrectionPageDetails?plantId=${plantId}&sensorId=${sensorId}&startDate=${startDate}`
    );

    return data; // Return the response data
  } catch (err) {
    console.error(err);
    return null; // Return null on error
  }
};

const saveDataCorrection = async (payload: {
  sensorId: string;
  comment: string;
  updatedPoints: { _id: string; value: number }[];
  deletedPoints: string[];
  plantId: string;
}) => {
  try {
    const response = await axiosInstance().post('/sensors/correctSensorData', payload);
    return response.data; // Return the response data if needed
  } catch (error) {
    throw new Error('Failed to save data correction');
  }
};

export {
  getWorkspaceDataforDataCorrection,
  getPlantDataforDataCorrection,
  getSensorDataforDataCorrection,
  fetchLineGraphData,
  saveDataCorrection,
};
