import { Box, Divider, Stack } from "@mui/material";
import { useState } from "react";
import {
  decodeTokenBody,
  getAllCachedLoginEntries,
  removeCachedLoginEntry,
} from "../../utilities/cachedLoginEntries.utils";

import { ICachedLoginEntry } from "../../Interfaces/login.interfaces";
import { ITokenBody } from "../../Interfaces/tokenInterface";
import PersistentUserItem from "./persistentUserItem";

function addTokenToLoginEnteries(entries : ICachedLoginEntry[]){
  let loginEntriesList = entries?.map((loginEntry) => {
    return { id: loginEntry.id, ...decodeTokenBody(loginEntry.token) };
  });
  return loginEntriesList || [];
}

export default function PersistentUserList() {
  const [loginEntriesList, setLoginEntriesList] = useState<ITokenBody[]>(addTokenToLoginEnteries(getAllCachedLoginEntries()));

  function handleRemoveEntry(userId : string){
    removeCachedLoginEntry(userId);
    setLoginEntriesList(addTokenToLoginEnteries(getAllCachedLoginEntries()));
  }

  if (loginEntriesList.length > 0)
    return (
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
          height: "50vh",
          overflowY: "scroll"
        }}
      >
        <Stack
          direction="column"
          gap={2}
          divider={<Divider orientation="horizontal" flexItem />}
        >
          {loginEntriesList.map((loginEntry) => {
            return (
              <PersistentUserItem
                loginEntry={loginEntry}
                handleRemoveEntry={handleRemoveEntry}
              />
            );
          })}
        </Stack>
      </Box>
    );
  else return null;
}
