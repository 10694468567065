import { useEffect, useState } from 'react';
import LayoutComponent from '../components/LayoutComponent';
import {
  Box,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Grid,
  Button,
} from '@mui/material';
import { getPlantFeatureAuthDataForCSM } from '../services/plantFeatureAuth';
import PlantAIConfigDialogBox from '../components/AIAnalysisConfig/PlantAIConfigDialogBox';
import ChatGptIcon from '../assets/icons/ChatGPTOutline.svg';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import moment from 'moment';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import TextField from '@mui/material/TextField';
// import FilterMenu from "../components/AIAnalysisConfig/FilterComponent";

export default function AIAnalysisConfig() {
  const [plantsFeatureAuthData, setPlantsFeatureAuthData] = useState([]);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [selectedPlantFeatureAuthData, setSelectedPlantFeatureAuthData] = useState(null);
  const [refetchData, setRefetchData] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  // const [filteredPlantsFeatureAuthData, setFilteredPlantsFeatureAuthData] =
  //   useState([]);

  useEffect(() => {
    getPlantFeatureAuthDataForCSM().then(data => {
      setPlantsFeatureAuthData(data);
      // setFilteredPlantsFeatureAuthData(data);
      console.log(data);
    });
  }, [refetchData]);

  // const filterData = (options: any) => {
  //   // Check if 'Show All' is selected
  //   const showAll = options.find(
  //     (option: any) => option.label === "Show All" && option.checked
  //   );

  //   if (showAll) {
  //     return plantsFeatureAuthData; // If 'Show All', return all data
  //   }

  //   // Filter logic for other options
  //   return plantsFeatureAuthData.filter((data: any) => {
  //     const hasPaidPlan =
  //       data?.featureList?.[0]?.featureSettings?.plantLevelSettings?.paidPlan;
  //     const hasAccess = data?.featureList?.[0]?.access;

  //     // Initialize filter matches as an array of booleans for each condition
  //     const matchesFilter = options.map((option: any) => {
  //       switch (option.label) {
  //         case "Show Paid":
  //           return option.checked && hasPaidPlan;
  //         case "Show Free":
  //           return option.checked && !hasPaidPlan;
  //         case "Show with feature":
  //           return hasAccess;
  //         case "Show w/o feature":
  //           return !hasAccess;
  //         default:
  //           return true;
  //       }
  //     });

  //     // The plant data should be included if all conditions in matchesFilter are true
  //     return matchesFilter.every(Boolean);
  //   });
  // };

  return (
    <LayoutComponent>
      <Typography variant="h4" sx={{ fontSize: '20px', color: '#666666' }} mt={4}>
        AI Analysis Config
      </Typography>
      <Stack direction="row" gap={3} mt={4}>
        <TextField
          id="outlined-basic"
          label="Search for plant"
          variant="outlined"
          fullWidth
          sx={{
            background: '#EFF5FA',
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                border: '1px solid #183650',
              },
              '&:hover fieldset': {
                borderColor: '#183650', // Border color on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: '#183650', // Border color when the TextField is focused
              },
              '& input': {
                fontSize: '14px', // Adjust font size as needed
              },
            },
            '& .MuiInputLabel-root': {
              fontSize: '14px', // Adjust font size of the label
            },
            height: '56px',
          }}
          onChange={event => {
            setSearchQuery(event.target.value);
          }}
        />
        {/* <IconButton
          sx={{
            display: "grid",
            placeItems: "center",
            border: "1px solid #18365099",
            padding: "16px",
            borderRadius: "4px",
          }}
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <FilterListIcon sx={{ color: "black" }} />
        </IconButton> */}
        {/* <FilterMenu
          options={[
            // { label: "Show All", checked: true },
            { label: "Show Paid", checked: true },
            { label: "Show Free", checked: true },
            { label: "Show with feature", checked: true },
            { label: "Show w/o feature", checked: true },
          ]}
          onSelectionChange={(updatedOptions) => {
            console.log("updatedOptions : ", updatedOptions);
            console.log("filtered  : ", filterData(updatedOptions));
            setFilteredPlantsFeatureAuthData(filterData(updatedOptions));
          }}
        /> */}
        <Button
          variant="outlined"
          sx={{
            width: '190px',
            height: '56px',
            color: '#183650',
            border: '1px solid #183650',
            fontSize: '14px',
          }}
        >
          Search
        </Button>
      </Stack>
      <Box mt={2}>
        {plantsFeatureAuthData
          .filter((data: any) => {
            return data?.plantId?.plantName
              ?.toLowerCase()
              ?.includes(searchQuery?.toLocaleLowerCase());
          })
          .map((data: any, featureAuthDataIndex: number) => {
            return (
              <Accordion
                TransitionProps={{ mountOnEnter: true, timeout: 500 }}
                elevation={0}
                disableGutters={true}
                //@ts-ignore
                square="false"
                sx={{
                  borderRadius: '5px',
                  border: '1px solid #DAF1F1',
                  marginBottom: '20px',
                  overflow: 'hidden',
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <IconButton>
                      <ExpandCircleDownOutlinedIcon
                        sx={{
                          color: '#999999',
                          width: '24px',
                          height: '24px',
                          alignSelf: 'flex-start',
                        }}
                      />
                    </IconButton>
                  }
                  sx={{
                    background: featureAuthDataIndex % 2 == 0 ? '#FBFEFE' : '#ffffff',
                  }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Stack direction="row" alignItems="center" sx={{ width: '100%' }} gap={2}>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      alignItems={{ xs: 'flex-start', sm: 'center' }}
                      gap={2}
                    >
                      <Box sx={{ fontSize: '16px' }}>{data?.plantId?.plantName}</Box>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <Box
                          sx={{
                            fontSize: '13px',
                            border: '1px solid #DAF1F1',
                            borderRadius: '100px',
                            background: '#fff',
                          }}
                          py={1}
                          px={2}
                        >
                          {data?.featureList?.[0]?.featureSettings?.plantLevelSettings?.paidPlan
                            ? 'Paid'
                            : 'Free'}
                        </Box>
                        <Box
                          sx={{
                            fontSize: '13px',
                            border: '1px solid #DAF1F1',
                            borderRadius: '100px',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '5px',
                            background: '#fff',
                          }}
                          py={1}
                          px={2}
                        >
                          <img src={ChatGptIcon} style={{ width: '16px', height: '16px' }} />
                          {data?.featureList?.[0]?.featureSettings?.plantLevelSettings?.gptModel
                            ? data?.featureList?.[0]?.featureSettings?.plantLevelSettings?.gptModel?.toUpperCase()
                            : 'gpt-4'?.toUpperCase()}
                        </Box>
                      </Stack>
                      <Box
                        sx={{
                          fontSize: '13px',
                          border: '1px solid #DAF1F1',
                          borderRadius: '100px',
                          maxWidth: '150px',
                          display: 'grid',
                          placeItems: 'center',
                          background: '#fff',
                        }}
                        py={1}
                        px={2}
                      >
                        <Box>{`Analytics | ${data?.featureList?.[0]?.totalUsage}/${
                          data?.featureList?.[0]?.creditsAvailable +
                          data?.featureList?.[0]?.totalUsage
                        } | ${data?.featureList?.[0]?.creditsAvailable}`}</Box>
                      </Box>
                    </Stack>
                    <Stack direction="row" sx={{ marginLeft: 'auto' }}>
                      <Box>
                        <IconButton disableRipple>
                          {data?.featureList?.[0].access ? (
                            <CheckBoxOutlinedIcon
                              sx={{
                                color: '#3DA3F5',
                                width: '19px',
                                height: '19px',
                              }}
                            />
                          ) : (
                            <CheckBoxOutlineBlankOutlinedIcon
                              sx={{
                                color: '#9999994D',
                                width: '19px',
                                height: '19px',
                              }}
                            />
                          )}
                        </IconButton>
                      </Box>
                      <Box>
                        <IconButton
                          onClick={event => {
                            event.stopPropagation();
                            setSelectedPlantFeatureAuthData(data);
                            setOpenDialogBox(true);
                          }}
                        >
                          <EditOutlinedIcon
                            sx={{
                              color: '#999999',
                              width: '18px',
                              height: '18px',
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Stack>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails sx={{ borderRadius: '10px' }}>
                  <Grid container>
                    <Grid item sx={{ width: '100%' }}>
                      <Box borderRadius={1} mt={1}>
                        <Box>
                          {data?.featureList?.[0]?.userUsage?.map((usage: any) => {
                            return (
                              <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                alignItems={{
                                  sm: 'flex-start',
                                  md: 'center',
                                }}
                                gap={2}
                                sx={{ width: '100%' }}
                              >
                                <Stack sx={{ gap: '5px' }}>
                                  <Box sx={{ fontSize: '14px' }}>
                                    <span style={{ color: '#CECECE' }}>—</span>
                                    {` ${usage?.userId?.name}`}
                                  </Box>
                                  <Box
                                    sx={{
                                      fontSize: '10px',
                                      color: '#7A848D',
                                      marginLeft: '16px',
                                    }}
                                  >
                                    {usage?.userId?.email}
                                  </Box>
                                </Stack>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  gap={{ xs: 1, md: 2 }}
                                  sx={{ width: '100%' }}
                                >
                                  <Box
                                    sx={{
                                      fontSize: { xs: '11px', md: '13px' },
                                      border: '1px solid #DAF1F1',
                                      borderRadius: '100px',
                                    }}
                                    py={1}
                                    px={2}
                                  >
                                    {usage?.userId?.role}
                                  </Box>
                                  <Box
                                    sx={{
                                      fontSize: { xs: '11px', md: '13px' },
                                      border: '1px solid #DAF1F1',
                                      borderRadius: '100px',
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                      gap: '5px',
                                    }}
                                    py={1}
                                    px={2}
                                  >
                                    <AccessTimeIcon
                                      sx={{
                                        width: '16px',
                                        height: '16px',
                                        color: '#3DA3F5',
                                      }}
                                    />
                                    {usage?.lastUsed
                                      ? moment(usage?.lastUsed, 'YYYY-MM-DDTHH:mm:ss.SSSZ').format(
                                          'MMM DD YYYY hh:mm A'
                                        )
                                      : '-'}
                                  </Box>
                                  <Box
                                    sx={{
                                      fontSize: { xs: '11px', md: '13px' },
                                      border: '1px solid #DAF1F1',
                                      borderRadius: '100px',
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                    py={1}
                                    px={2}
                                  >
                                    <Box
                                      sx={{
                                        display: { xs: 'none', md: 'block' },
                                      }}
                                    >{`Analytics |`}</Box>
                                    <Box
                                      sx={{ marginLeft: '5px' }}
                                    >{` ${usage?.featureUsage}`}</Box>
                                  </Box>
                                </Stack>
                              </Stack>
                            );
                          })}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })}
      </Box>
      <PlantAIConfigDialogBox
        open={openDialogBox}
        featureAuthData={selectedPlantFeatureAuthData}
        onClose={() => setOpenDialogBox(false)}
        setRefetchData={setRefetchData}
      />
      {/* <FilterMenu
        options={[{ label: "Show All", checked: false }]}
        anchorElement={anchorEl}
        onSelectionChange={() => {}}
      /> */}
    </LayoutComponent>
  );
}
