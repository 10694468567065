import { Box, Stack } from '@mui/material';
import { getManualEntryWidgetData } from '../../services/dashboard/widget';
import { getUserDetails } from '../../services/profile';
import { getAlertsForClientWithFiltersAndTimeRange, getPlantsForUser } from '../../services/tickets';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import ClockIcon from '@mui/icons-material/WatchLater';
import ClockOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import lodash from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TIME_RANGE_MAPPINGS } from '../../constants/constants';
import { WIDGET_THEMES } from '../../constants/widgetConfig.constants';
import LinkIcon from '../../core-components/assets/icons/linkIcon.svg';
import GeneralisedPopupToolBar from '../../core-components/theme/components/HeaderComponents/GeneralisedPopupToolBar';
import DateAndTime from '../../globalComponents/dateAndTime/DateAndTime';
import CustomDialog from '../../globalComponents/dialogs/CustomDialog';
import { calculateTimeFrame, handleDownloadCSVForManualWidget } from '../../utilities/helpers';
import AddTicketBox from './subComponents/AddTicketBox';
import ManualEntryRow from './subComponents/ManualEntryRow';

const styles = {
  iconStyle: { width: '16px', height: '16px', color: '#ACB3B9' },
};

export default function ManualEntryWidget({
  widgetId,
  widgetStructure,
  refresh,
  enableNewToolBar = false,
  timeFrame,
  setParent,
  changedWidgets,
  setChangedWidgets,
  setIsBottomBarOpen,
  isBottomBarOpen,
}: any) {
  const [alerts, setAlerts] = useState([]);
  const [userId, setUserId] = useState('');
  const [widgetData, setWidgetData] = useState<any>(null);
  const [background, setBackground] = useState('#ddd');
  const [fontColor, setFontColor] = useState('#ddd');
  const [showPopupToolBar, setShowPopupToolBar] = useState(false);
  const [openAddTicketBox, setOpenAddTicketBox] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  //@ts-ignore
  const [customTimeFrame, setCustomTimeFrame] = useState<any>(null);
  const [customTimeRangeSettings, setCustomTimeRangeSettings] = useState<any>(null);
  const [showClock, setShowClock] = useState<string>('off');
  const [widgetTimeFrame, setWidgetTimeFrame] = useState<any>(null);
  const [widgetDateAndTime, setWidgetDateAndTime] = useState(null);
  const [customGranularity, setCustomGranularity] = useState(null);
  //@ts-ignore
  const [sendParentInGetWidgeDataApi, setSendParentInGetWidgetDataApi] = useState('dashboard');
  const [loading, setLoading] = useState(false);
  const [tagsFilter, setTagsFilter] = useState([]);
  const [openClosedFilter, setOpenClosedFilter] = useState({ open: true, closed: true });
  const [priorityTypeFilter, setPriorityTypeFilter] = useState([]);
  const [alertTypeFilters, setAlertTypeFilters] = useState({
    manualAlerts: true,
    automaticAlerts: true,
  });
  const [selectedPlantsFilter, setSelectedPlantsFilter] = useState([]);
  const [refetch, setRefetch] = useState(false);
  const navigate = useNavigate();

  const getFilters = () => {
    let filters: any = {};
    if (openClosedFilter.open && openClosedFilter.closed) {
    } else if (openClosedFilter.open) {
      filters['isOpen'] = true;
    } else if (openClosedFilter.closed) {
      filters['isOpen'] = false;
    }

    if (tagsFilter.length > 0) {
      filters['tags'] = tagsFilter.map((tag: any) => tag._id);
    }
    if (priorityTypeFilter.length > 0) {
      filters['priority'] = priorityTypeFilter;
    }

    return filters;
  };

  useEffect(() => {
    getUserDetails().then((data: any) => {
      setUserId(data?.data?.user?._id);
    });
  }, []);

  useEffect(() => {
    if (userId != '') {
      getPlantsForUser().then(data => {
        setSelectedPlantsFilter(data.map((plant: any) => plant.plantId));
      });
    }
  }, [userId]);

  useEffect(() => {
    if (userId != '') {
      let tempTimeFrame = showClock == 'on' ? widgetTimeFrame : timeFrame;
      setLoading(true);
      let filters = getFilters();
      getAlertsForClientWithFiltersAndTimeRange(
        userId,
        selectedPlantsFilter,
        tempTimeFrame,
        filters,
        alertTypeFilters
      ).then(data => {
        setAlerts(data);
        setLoading(false);
      });
    }
  }, [
    refetch,
    timeFrame,
    customTimeFrame,
    showClock,
    customTimeRangeSettings,
    openClosedFilter,
    selectedPlantsFilter,
    priorityTypeFilter,
  ]);

  useEffect(() => {
    setLoading(true);
    getManualEntryWidgetData(widgetId, widgetStructure).then(data => {
      setWidgetData(data);
      setOpenClosedFilter({ open: data?.filterSettings?.isOpen, closed: data?.filterSettings?.isClosed });
      setAlertTypeFilters(prev => ({...prev, automaticAlerts : data?.filterSettings?.isAutoGenTicket}))
      setBackground(WIDGET_THEMES[data.widgetLook?.theme || 'default'].backgroundColor);
      setFontColor(WIDGET_THEMES[data.widgetLook?.theme || 'default'].fontColor);
      handleShowClock(data);
      setWidgetDateAndTimeFunction(data);
      setLoading(false);
    });
  }, [refresh]);

  const handleCloseDialog = () => {
    setOpenCalendar(false);
  };

  const downloadCSV = () => {
    handleDownloadCSVForManualWidget(alerts);
  };

  const add530HoursInTimeFrame = (timeFrame: any | null) => {
    if (!timeFrame)
      return {
        startDate: new Date(),
        endDate: new Date(),
      };
    const newTimeFrame = {
      startDate: new Date(timeFrame?.startDate?.getTime() + 1000 * 60 * 60 * 5.5).toISOString(),
      endDate: new Date(timeFrame?.endDate?.getTime() + 1000 * 60 * 60 * 5.5).toISOString(),
    };

    return {
      ...newTimeFrame,
    };
  };

  const handleWidgetUpdate = (showClock: string) => {
    const updatedWidget = {
      dashboardId: widgetData.dashboardPageId,
      widgetId: widgetId || '',
      selectedGranularity: showClock == 'on' ? customGranularity : timeFrame.granularity,
      selectedChart: {
        widgetType: 'ManualEntryWidget',
        graphType: 'ManualEntryWidget',
      },
      selectedTimeRange:
        showClock == 'on' && customTimeRangeSettings
          ? customTimeRangeSettings
          : // : null
            (function () {
              let mapping = TIME_RANGE_MAPPINGS[timeFrame?.timeRangeLabel];
              let unit = mapping?.unit;
              let unitMultiplier = mapping?.unitMultiplier;
              return {
                unit: unit,
                unitMultiplier: unitMultiplier,
                timeRangeType: timeFrame.timeRangeType,
                startTime: timeFrame.startDate,
                endTime: timeFrame.endDate,
              };
            })(),
      applyCustomSettings: showClock == 'on' ? true : false,
    };

    updateChangedWidgets(updatedWidget, showClock);
  };

  const updateChangedWidgets = (updatedWidget: any, showClock: string) => {
    const tempChangedWidgets = [...changedWidgets];
    const existingWidgetIndex = tempChangedWidgets.findIndex(
      widget => widget.widgetId === updatedWidget.widgetId
    );
    let showBottomBar: any = false;
    let clone1;
    let clone2;
    if (existingWidgetIndex !== -1 && showClock == 'on') {
      console.log('existing widget 1');
      clone1 = lodash.cloneDeep(tempChangedWidgets[existingWidgetIndex]);
      clone2 = lodash.cloneDeep(updatedWidget);
      if (clone1 && clone2) {
        if (
          clone1.selectedTimeRange?.timeRangeType == 'relative' &&
          clone2.selectedTimeRange?.timeRangeType == 'relative'
        ) {
          delete clone1.selectedTimeRange.startTime;
          delete clone1.selectedTimeRange.endTime;
          delete clone2.selectedTimeRange.startTime;
          delete clone2.selectedTimeRange.endTime;
        } else {
          clone1.selectedTimeRange.startTime = new Date(
            clone1.selectedTimeRange.startTime
          ).toISOString();
          clone1.selectedTimeRange.endTime = new Date(
            clone1.selectedTimeRange.endTime
          ).toISOString();
          clone2.selectedTimeRange.startTime = new Date(
            clone2.selectedTimeRange.startTime
          ).toISOString();
          clone2.selectedTimeRange.endTime = new Date(
            clone2.selectedTimeRange.endTime
          ).toISOString();
        }
      }
      showBottomBar = !lodash.isEqual(clone1, clone2);
    } else if (showClock == 'disabled') {
      console.log('existing widget 2');
      showBottomBar =
        tempChangedWidgets[existingWidgetIndex]?.applyCustomSettings !=
        updatedWidget?.applyCustomSettings;
    }

    if (existingWidgetIndex !== -1) {
      let newUpdatedWidget = {
        ...tempChangedWidgets[existingWidgetIndex],
        ...updatedWidget,
      };
      tempChangedWidgets.splice(existingWidgetIndex, 1);
      tempChangedWidgets.push(newUpdatedWidget);
    } else {
      tempChangedWidgets.push(updatedWidget);
    }

    setChangedWidgets(tempChangedWidgets);
    // if (!lodash.isEqual(tempChangedWidgets, changedWidgets))
    if (showBottomBar) {
      setParent('widget');
      setIsBottomBarOpen(true);
    }
  };

  const handleEditClick = () => {
    console.log('changedWidgets:', changedWidgets);
    setParent('widget');
    setOpenCalendar(true);
    setSendParentInGetWidgetDataApi('widget');
    if (isBottomBarOpen) {
      setIsBottomBarOpen(false);
    }
  };

  const navigateToLink = () => {
    navigate('/tickets');
  };

  const handleClockClick = (value: any) => {
    setShowClock(value);
    setSendParentInGetWidgetDataApi('clockWidget');
    handleWidgetUpdate(value);
    setParent('widget');
  };

  const capitalizeFirstLetter = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  const formatDateTime = (date: Date) => {
    const dateString = date.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
    });
    const timeString = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
    });
    return `${dateString} ${timeString}`;
  };

  const formatTimeRange = () => {
    if (
      showClock == 'on' &&
      customTimeRangeSettings?.startTime &&
      customTimeRangeSettings?.endTime
    ) {
      const startTime = new Date(customTimeRangeSettings.startTime);
      const endTime = new Date(customTimeRangeSettings.endTime);
      return `${formatDateTime(startTime)} - ${formatDateTime(endTime)} | ${capitalizeFirstLetter(
        'hours'
      )}`;
    } else {
      return `${formatDateTime(timeFrame.startDate)} - ${formatDateTime(timeFrame.endDate)} `;
    }
  };

  const handleShowClock = (widgetData: any) => {
    // console.log('showclock : ', showClock, widgetData?.advancedWidgetSettings);
    if (showClock === 'off' && widgetData?.advancedWidgetSettings?.applyCustomSettings) {
      let newTimeFrame = add530HoursInTimeFrame(
        calculateTimeFrame(widgetData.customTimeRangeSettings)
      );
      setWidgetTimeFrame(newTimeFrame);
      setShowClock('on');

      const { startDate: startTime, endDate: endTime }: any = calculateTimeFrame(
        widgetData.customTimeRangeSettings
      );

      let customTimeRangeSettings = {
        ...widgetData.customTimeRangeSettings,
        startTime,
        endTime,
      };
      setCustomTimeRangeSettings(customTimeRangeSettings);
    }
  };

  const setWidgetDateAndTimeFunction = (data: any) => {
    const widgetDateAndTime: any = {
      widgetId,
      factoryGranularitySettings: data?.factoryGranularitySettings,
      factoryTimeRangeSettings: data?.factoryTimeRangeSettings,
      customGranularitySettings: data?.customGranularitySettings,
      customTimeRangeSettings: data?.customTimeRangeSettings,
      advancedWidgetSettings: data?.advancedWidgetSettings,
    };

    // console.log('selected range 2 : ', widgetDateAndTime);

    setWidgetDateAndTime(widgetDateAndTime);
  };

  return (
    <Box
      sx={{ width: 'inherit', height: 'inherit', boxSizing: 'border-box' }}
      onMouseEnter={(event: any) => {
        // console.log('mouse enter');
        setShowPopupToolBar(true);
      }}
      onMouseLeave={(event: any) => {
        setShowPopupToolBar(false);
      }}
    >
      <GeneralisedPopupToolBar
        open={showPopupToolBar}
        enableToolBar={enableNewToolBar}
        iconConfigs={[
          {
            icon: <AddIcon sx={styles.iconStyle} />,
            onClick: event => {
              event.stopPropagation();
              setOpenAddTicketBox(true);
            },
          },
          {
            icon: <DownloadIcon sx={styles.iconStyle} />,
            onClick: event => {
              event.stopPropagation();

              downloadCSV();
            },
          },
          {
            icon: <img src={LinkIcon} style={styles.iconStyle} />,
            onClick: event => {
              event.stopPropagation();
              navigateToLink();
            },
          },
          {
            icon: <ModeEditOutlineIcon sx={styles.iconStyle} />,
            onClick: event => {
              event.stopPropagation();
              handleEditClick();
            },
            showIcon: !['number'].includes(widgetData?.widgetType) ? true : false,
          },
        ]}
      />
      <Box
        sx={{
          width: '100%',
          height: '100%',
          border: `1px solid ${background}`,
          overflow: 'hidden',
          boxSizing: 'border-box',
          background: '#fff',
        }}
        borderRadius={3}
        pb={0}
      >
        <Stack
          direction="column"
          gap={1}
          sx={{ height: `calc(100% - 35px)`, width: '100%', boxSizing: 'border-box' }}
          px={3}
          pt={3}
        >
          <Box sx={{ height: '20px', color: fontColor, fontWeight: 600 }}>
            {widgetData?.widgetName}
          </Box>
          <Stack
            sx={{ width: 'inherit', color: '#484F54', fontSize: '14px', boxSizing: 'border-box' }}
            direction="row"
            mt={1}
            px={2}
          >
            <Box sx={{ width: '55.5%' }}>Description</Box>
            <Box sx={{ width: '10%' }}>Plant Name</Box>
            <Box sx={{ width: '30%' }}>Type & Tags</Box>
          </Stack>

          {loading && (
            <Box
              sx={{
                overflowY: 'scroll',
                height: `calc(100% - 30px)`,
                display: 'grid',
                placeItems: 'center',
              }}
              gap={2}
              mt={2}
            >
              <CircularProgress sx={{ color: fontColor }} />
            </Box>
          )}
          {!loading && alerts.length > 0 && (
            <Stack sx={{ overflowY: 'scroll', height: `calc(100% - 30px)` }} gap={2} mt={2}>
              {alerts.map(alert => {
                return (
                  <ManualEntryRow
                    alert={alert}
                    theme={widgetData?.widgetLook?.theme}
                    refreshRow={refresh}
                  />
                );
              })}
            </Stack>
          )}
          {!loading && alerts.length == 0 && (
            <Box
              sx={{
                overflowY: 'scroll',
                height: `calc(100% - 30px)`,
                display: 'grid',
                placeItems: 'center',
              }}
              gap={2}
              mt={2}
            >
              <Box>No Tickets!</Box>
            </Box>
          )}
        </Stack>
        <Box
          sx={{
            height: '35px',
            // borderTop: `1px solid ${background}`,
            background: background,
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            boxSizing: 'border-box',
          }}
          p={2}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <Box sx={{ display: 'grid', placeItems: 'center' }}>
              {showClock == 'on' && (
                <ClockIcon
                  sx={{
                    width: '16px',
                    height: '16px',
                    cursor: 'pointer',
                    color: '#fff',
                  }}
                  onClick={() => handleClockClick('disabled')}
                />
              )}
              {showClock === 'disabled' && (
                <ClockOutlinedIcon
                  sx={{
                    width: '16px',
                    height: '16px',
                    cursor: 'pointer',
                    color: '#fff',
                  }}
                  onClick={() => handleClockClick('on')}
                />
              )}
            </Box>
            <Box
              sx={{
                color: '#fff',
                fontSize: '13px',
                // lineHeight: (containerWidth / 100) * 3,
                lineHeight: '15px',
              }}
            >
              {formatTimeRange()}
            </Box>
          </Stack>
        </Box>
      </Box>

     { openAddTicketBox && <AddTicketBox
        open={openAddTicketBox}
        handleClose={() => {
          setOpenAddTicketBox(false);
        }}
        handleSuccess={() => setRefetch(!refetch)}
      /> }
      <CustomDialog open={openCalendar} onClose={handleCloseDialog}>
        <DateAndTime
          parent="widget"
          onClose={handleCloseDialog}
          widgetDateAndTime={widgetDateAndTime}
          setWidgetDateAndTime={setWidgetDateAndTime}
          changedWidgets={changedWidgets || []}
          setChangedWidgets={setChangedWidgets}
          setIsBottomBarOpen={setIsBottomBarOpen}
          setCustomTimeRangeSettings={setCustomTimeRangeSettings}
          setWidgetTimeFrame={setWidgetTimeFrame}
          showClock={showClock}
          setShowClock={setShowClock}
          timeFrame={timeFrame}
          setCustomGranularity={setCustomGranularity}
          setParent={setParent}
          selectChart={widgetData?.widgetType}
          setSelectedChart={(abc: string) => {}}
          dashboardId={widgetData?.dashboardPageId}
          ///manual entry specific props
          openClosedFilter={openClosedFilter}
          setOpenClosedFilter={setOpenClosedFilter}
          priorityTypeFilter={!!priorityTypeFilter?.length ? priorityTypeFilter : (widgetData?.filterSettings?.selectedPriority || [])}
          setPriorityTypeFilter={setPriorityTypeFilter}
          tagsFilter={tagsFilter}
          advancedFilters={widgetData?.filterSettings}
          setTagsFilter={setTagsFilter}
          alertTypeFilters={alertTypeFilters}
          setAlertTypeFilters={setAlertTypeFilters}
          selectedPlantsFilter={!!selectedPlantsFilter?.length ? selectedPlantsFilter : (widgetData?.filterSettings?.selectedPlants || [])}
          setSelectedPlantsFilter={setSelectedPlantsFilter}
        />
      </CustomDialog>
    </Box>
  );
}
