export default function RemoveRoadFilledIcon({
  width = '22',
  height = '22',
  fillColor = '#183650',
  fillInside = 'white',
}: any) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.8947 16.0815H12.7831V19.8583H10.8947V16.0815Z" fill={fillColor} />
      <path d="M10.8947 10.4162H12.7831V14.1931H10.8947V10.4162Z" fill={fillColor} />
      <path d="M10.8947 4.75098H12.7831V8.52782H10.8947V4.75098Z" fill={fillColor} />
      <path d="M17.5042 4.75098H19.3926V19.8583H17.5042V4.75098Z" fill={fillColor} />
      <path d="M4.28522 4.75098H6.17364V16.648H4.28522V4.75098Z" fill={fillColor} />
      <path
        d="M4.28522 17.9699H6.17364H9.00627V19.8583H6.17364H4.28522H1.45259V17.9699H4.28522Z"
        fill={fillColor}
      />
    </svg>
  );
}
