export default function ResetIcon({
  width = '22',
  height = '22',
  fillColor = '#183650',
  fillInside = 'white',
}: any) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8369 5.43504V1.6582L16.558 6.37925L11.8369 11.1003V7.32346C8.71161 7.32346 6.17169 9.86339 6.17169 12.9887C6.17169 16.1141 8.71161 18.654 11.8369 18.654C14.9623 18.654 17.5022 16.1141 17.5022 12.9887H19.3906C19.3906 17.1621 16.0104 20.5424 11.8369 20.5424C7.66354 20.5424 4.28327 17.1621 4.28327 12.9887C4.28327 8.81531 7.66354 5.43504 11.8369 5.43504Z"
        fill={fillColor}
      />
    </svg>
  );
}
