export default function ArrowCircleDownFilled({
  width = '22',
  height = '22',
  fillColor = '#183650',
  fillInside = 'white',
}: any) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7253 3.91088C15.8893 3.91088 19.279 7.30059 19.279 11.4646C19.279 15.6285 15.8893 19.0182 11.7253 19.0182C7.56134 19.0182 4.17162 15.6285 4.17162 11.4646C4.17162 7.30059 7.56134 3.91088 11.7253 3.91088ZM11.7253 2.02246C6.51326 2.02246 2.2832 6.25252 2.2832 11.4646C2.2832 16.6766 6.51326 20.9067 11.7253 20.9067C16.9373 20.9067 21.1674 16.6766 21.1674 11.4646C21.1674 6.25252 16.9373 2.02246 11.7253 2.02246ZM12.6695 11.4646V7.68772H10.7811V11.4646H7.94846L11.7253 15.2414L15.5021 11.4646H12.6695Z"
        fill={fillColor}
      />
    </svg>
  );
}
